import { Button, Modal, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function TempPasswordModal({ userName, show }) {
  const navigate = useNavigate();
  return (
    <Modal show={show}>
      <Modal.Body>
        <div style={{ marginTop: '10px', marginBottom: '30px', textAlign: 'center' }}>
          <i className="mdi mdi-alert-circle-outline" style={{ fontSize: '45px', color: '#c63328' }}></i>
          <h5>Olá, {userName}!</h5>
          <h5>
            Notamos que você está usando uma senha gerada pelo sistema. Por motivos de segurança, é obrigatório que você
            defina uma nova senha.
          </h5>
        </div>
        <Row>
          <Col className="text-center">
            <Button variant="primary" size="md" onClick={() => navigate('/account/profile')}>
              OK
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
