import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Root = () => {
  const userData = useSelector(state => state.user.data);

  const getRootUrl = () => {
    let url = '';
    const userDataProfiles = userData?.profiles ?? [];
    if (
      userDataProfiles.includes('admin') ||
      userDataProfiles.includes('proposals_read') ||
      userDataProfiles.includes('proposals_write') ||
      userDataProfiles.includes('panel_loans_operational') ||
      userDataProfiles.includes('panel_loans_manager') ||
      userDataProfiles.includes('panel_foresight_operational') ||
      userDataProfiles.includes('panel_foresight_manager') ||
      userDataProfiles.includes('panel_insurance_operational') ||
      userDataProfiles.includes('panel_insurance_manager')
    ) {
      url = 'dashboard/';
    } else if (userDataProfiles.includes('insurance_read') || userDataProfiles.includes('insurance_write')) {
      url = 'insurance/policy';
    } else {
      url = 'account/profile';
    }
    return url;
  };

  const url = getRootUrl();

  return <Navigate to={`/${url}`} />;
};

export default Root;
